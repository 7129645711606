/* ==========================================================================
   MASTHEAD
   ========================================================================== */

.masthead {
	position: relative;
	border-bottom: 1px solid $border-color;
	-webkit-animation: $intro-transition;
	animation: $intro-transition;
	-webkit-animation-delay: 0.15s;
	animation-delay: 0.15s;
	z-index: 20;

	&__inner-wrap {
		@include clearfix;
		margin-left: auto;
		margin-right: auto;
		padding: 1em;
		max-width: 100%;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		font-family: $sans-serif-narrow;

		@include breakpoint($x-large) {
			max-width: $max-width;
		}

		nav {
			z-index: 10;
		}

		a {
			text-decoration: none;
		}
	}
}

.site-logo img {
	max-height: 2rem;
}

.site-title {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-item-align: center;
	align-self: center;
	font-weight: bold;
	// z-index: 20;
}

.site-subtitle {
	display: block;
	font-size: $type-size-8;
}

.masthead__menu {
	float: left;
	margin-left: 0;
	margin-right: 0;
	width: 100%;
	clear: both;

	.site-nav {
		margin-left: 0;

		@include breakpoint($small) {
			float: right;
		}
	}

	ul {
		margin: 0;
		padding: 0;
		clear: both;
		list-style-type: none;
	}
}

.masthead__menu-item {
	display: block;
	list-style-type: none;
	white-space: inherit;

	&--lg {
		padding-right: 2em;
		font-weight: 700;
	}
}

.dropdown {
	float: left;
	overflow: hidden;
}

.dropdown .dropbtn {
	border: none;
	outline: none;
	color: #6f777d;
	background-color: inherit;
	font-family: inherit;
	margin: 0;
}

@media only screen and (max-width: 600px) {
	.dropdown .dropbtn {
	    padding: 10px 20px;
		border-bottom: 1px solid #f2f3f3;
	}
}

.navbar a:hover,
.dropdown:hover .dropbtn {
	color: #53595e;
}

.dropdown-content {
	display: none;
	position: absolute;
	background-color: #f9f9f9;
	min-width: 160px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 1;
}

.dropdown-content a {
	float: none;
	color: black;
	padding: 12px 16px;
	text-decoration: none;
	display: block;
	text-align: left;
}

.dropdown-content a:hover {
	background-color: #ddd;
}

.dropdown:hover .dropdown-content {
	display: block;
}